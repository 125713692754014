import * as ApolloCommon from '@apollo/client';
import { PureQueryOptions } from '@apollo/client';
import * as Apollo from '@apollo/client/react/components';
import { Delete } from '@mui/icons-material';
import { Theme } from '@mui/material';
import * as Schema from 'generated/graphql/schema';
import { WithRouterProps } from 'next/dist/client/with-router';
import Router, { withRouter } from 'next/router';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { MutationButton, MutationIconButton } from '@/components/buttons/mutation-button';
import { lineDelete } from '@/graphql/mutations';
import { WithIAM, withIAM } from '@/hocs';
import { WithStyles, withStyles } from '@/hocs/with-styles';
import { WithWidth, isWidthUp, withWidth } from '@/hocs/with-width';
import DeleteConfirmationDialog from '@/views/dialogs/confirm/delete-confirmation-dialog';

interface Properties {
  handleOpenClose: (open: boolean) => void;
  disabledMessage?: string;
  lineId: string;
  open: boolean;
  refetchQueries?: PureQueryOptions[];
}

interface State {
  clearCache: () => void;
  openConfirmationDialog: boolean;
}

class DeleteButton extends React.Component<
  Properties & WithTranslation & WithIAM & WithRouterProps & WithWidth & WithStyles & { theme: Theme },
  State
> {
  readonly state: State = {
    clearCache: Function.prototype as State['clearCache'],
    openConfirmationDialog: false,
  };

  public render() {
    const { lineId, hasLineFeatures, width, theme, t, disabledMessage, refetchQueries } = this.props;
    const MutationBtn = isWidthUp('md', width) ? MutationButton : MutationIconButton;
    const styling = isWidthUp('md', width) ? { margin: theme.spacing() } : {};
    const iconStyles = isWidthUp('md', width) ? { marginRight: theme.spacing(), marginTop: '-3px' } : {};

    const label = t(['line:deleteLine'], { defaultValue: 'Delete line' });

    return (
      <Apollo.Mutation<Schema.LineDeleteMutation, Schema.LineDeleteMutationVariables>
        mutation={lineDelete}
        update={this.onUpdate}
        refetchQueries={refetchQueries}
        onCompleted={this.onCompleted}
      >
        {(mutation, { loading: mutationLoading }) => (
          <>
            <MutationBtn
              withIcon
              isRed
              aria-label={label}
              style={styling}
              loading={mutationLoading}
              onClick={this.openConfirmationDialog}
              disabled={!hasLineFeatures(lineId, ['Lines.Line'], 'M')}
              tooltipMsg={isWidthUp('md', width) ? '' : label}
              tooltipMsgDisabled={
                disabledMessage
                  ? disabledMessage
                  : t(['shared:noPermissionToEdit'], { defaultValue: "You don't have permission to edit this" })
              }
            >
              <Delete style={iconStyles} />
              {isWidthUp('md', width) ? label : ''}
            </MutationBtn>
            <DeleteConfirmationDialog
              open={this.state.openConfirmationDialog}
              onClose={this.closeConfirmationDialog}
              onConfirm={this.onDelete(mutation)}
              t={t}
              title={t(['line:confirmLineDeletion'], { defaultValue: 'Are you sure you wish to delete this line?' })}
            />
          </>
        )}
      </Apollo.Mutation>
    );
  }
  // tslint:disable-next-line
  private onUpdate = (proxy: any, _: unknown) => {
    // For properly deleting line in cache
    // Try to fix in Apollo 3
    this.setState({
      clearCache: () => {
        delete proxy.data.data[`Line:${this.props.lineId}`];
      },
    });
  };

  private onCompleted = async (data: unknown | null) => {
    if (!data) {
      return;
    }

    this.props.handleOpenClose(!this.props.open);
    if (this.state.clearCache) {
      this.state.clearCache();
    }
    await Router.replace('/lines');
  };

  private onDelete =
    (mutation: ApolloCommon.MutationFunction<Schema.LineDeleteMutation, Schema.LineDeleteMutationVariables>) =>
    async () =>
      mutation({ variables: { lineId: this.props.lineId } });

  private openConfirmationDialog = () => this.setState({ openConfirmationDialog: true });

  private closeConfirmationDialog = () => this.setState({ openConfirmationDialog: false });
}
const enhanceSave = compose<unknown, Properties>(
  withRouter,
  withWidth(),
  withIAM,
  withTranslation(['shared', 'line']),
  withStyles(() => ({}), { withTheme: true }),
);
export default enhanceSave(DeleteButton as React.ComponentType<unknown>);
