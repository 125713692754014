import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import * as i18next from 'i18next';
import React from 'react';

import DialogHeader from '@/components/dialogs/dialog-header';

interface Props {
  title: string | JSX.Element;
  open: boolean;
  t: i18next.TFunction;
  onClose: () => void;
  onConfirm: () => unknown;
}

const DeleteConfirmationDialog = <T extends Props>({ open, onClose, onConfirm, t, title }: T) => {
  const theme = useTheme<Theme>();
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      aria-labelledby="delete-confirmation-dialog"
      onClose={onClose}
      scroll="body"
    >
      <DialogHeader title={t(['shared:confirmDeletion'], { defaultValue: 'Confirm Deletion' })} onClose={onClose} />
      <DialogContent>
        <Typography variant="body2">{title}</Typography>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: theme.palette.error.main }} id="confirmDelete" onClick={onConfirm}>
          {t(['shared:delete'], { defaultValue: 'Delete' })}
        </Button>
        <Button id="cancelDeletionAndClose" onClick={onClose}>
          {t(['shared:cancel'], { defaultValue: 'Cancel' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
