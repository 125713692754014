import * as ApolloCommon from '@apollo/client';
import { PureQueryOptions } from '@apollo/client';
import * as Apollo from '@apollo/client/react/components';
import { Check } from '@mui/icons-material';
import { Theme } from '@mui/material';
import * as Schema from 'generated/graphql/schema';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { MutationButton, MutationIconButton } from '@/components/buttons/mutation-button';
import { lineUpdate } from '@/graphql/mutations';
import { WithIAM, withIAM } from '@/hocs';
import { WithStyles, withStyles } from '@/hocs/with-styles';
import { WithWidth, isWidthUp, withWidth } from '@/hocs/with-width';

interface LineInfo {
  name: string;
  description: string;
}

interface ReshapedNode {
  type: Schema.NodeType;
  peripheralId: string;
}

interface ReshapedEdge {
  from: string;
  to: string;
}

interface Properties {
  handleOpenClose: (open: boolean) => void;
  open: boolean;
  lineId: string;
  lineInfo: LineInfo;
  nodes: ReshapedNode[];
  edges: ReshapedEdge[];
  disabledMessage?: string;
  languageCode: string;
  refetchQueries?: PureQueryOptions[];
}

class UpdateButton extends React.Component<
  Properties & WithTranslation & WithIAM & WithWidth & WithStyles & { theme: Theme },
  {}
> {
  public render() {
    const {
      hasLineFeatures,
      width,
      theme,
      t,
      disabledMessage,
      lineId,
      lineInfo,
      nodes,
      edges,
      languageCode,
      refetchQueries,
    } = this.props;
    const MutationBtn = isWidthUp('md', width) ? MutationButton : MutationIconButton;
    const styling = isWidthUp('md', width) ? { margin: theme.spacing() } : {};
    const iconStyles = isWidthUp('md', width) ? { marginRight: theme.spacing(), marginTop: '-3px' } : {};

    return (
      <Apollo.Mutation<Schema.LineUpdateMutation, Schema.LineUpdateMutationVariables>
        mutation={lineUpdate}
        onCompleted={this.handleOpenClose}
        awaitRefetchQueries
        refetchQueries={refetchQueries}
      >
        {(updateLine, { loading: mutationLoading }) => {
          const label = t(['line:updateLineSettings'], { defaultValue: 'Update line settings' });
          const variables: Schema.LineUpdateMutationVariables = {
            lineId,
            name: lineInfo.name,
            description: lineInfo.description,
            nodes,
            edges,
            languageCode,
          };
          return (
            <MutationBtn
              withIcon
              color="primary"
              aria-label={label}
              style={styling}
              loading={mutationLoading}
              onClick={this.onUpdateLine(updateLine, variables)}
              disabled={!hasLineFeatures(lineId, ['Lines.Line'], 'M')}
              tooltipMsg={isWidthUp('md', width) ? '' : label}
              tooltipMsgDisabled={
                disabledMessage
                  ? disabledMessage
                  : t(['shared:noPermissionToEdit'], { defaultValue: "You don't have permission to edit this" })
              }
            >
              <Check style={iconStyles} />
              {isWidthUp('md', width) ? label : ''}
            </MutationBtn>
          );
        }}
      </Apollo.Mutation>
    );
  }
  private handleOpenClose = (data: unknown | null) => {
    if (!data) {
      return;
    }

    return this.props.handleOpenClose(!this.props.open);
  };

  private onUpdateLine =
    (
      updateLine: ApolloCommon.MutationFunction<Schema.LineUpdateMutation, Schema.LineUpdateMutationVariables>,
      variables: Schema.LineUpdateMutationVariables,
    ) =>
    async () =>
      updateLine({ variables });
}
const enhanceSave = compose<unknown, Properties>(
  withWidth(),
  withIAM,
  withTranslation(['shared', 'line']),
  withStyles(() => ({}), { withTheme: true }),
);
export default enhanceSave(UpdateButton as React.ComponentType<unknown>);
