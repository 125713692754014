import { PureQueryOptions, useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import { isEmpty } from 'lodash';
import React, { FC, useCallback, useState } from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import DialogHeader from '@/components/dialogs/dialog-header';
import DiscardChangesDialog from '@/components/dialogs/discard-changes-dialog';
import LoadingOrError from '@/components/loading/loading-or-error';
import { lineSettings } from '@/graphql/queries';
import { isWidthUp } from '@/hocs/with-width';
import { useWidth } from '@/hooks';
import * as Types from '@/types';
import WrapRequiredFieldsDescriptionComponent from '@/views/common-components/wrap-required-fileds-description';
import DeleteButton from '@/views/dialogs/line-settings/delete-button';
import SelectSensor from '@/views/dialogs/line-settings/setup-line/select-sensor';
import { PartialSensor, SensorGroup } from '@/views/dialogs/line-settings/setup-line/select-sensor-group';
import SpecifyLineInfo from '@/views/dialogs/line-settings/setup-line/specify-line-info';
import UpdateButton from '@/views/dialogs/line-settings/update-button';

import LineListTopology from './setup-line/line-list-topology';

const useStyles = makeStyles()((theme: Theme) => ({
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: '40px',
    },
  },
  textFields: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginTop: '20px',
  },
  closeButton: {
    marginRight: theme.spacing(),
    marginTop: '-3px',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
}));

interface LineInfo {
  name: string;
  description: string;
}

export interface Properties {
  key?: string;
  lineId: string;
  handleOpenClose: (open: boolean) => void;
  open: boolean;
  ignoreDiscardValidation?: boolean;
  refetchQueries?: PureQueryOptions[];
  deleteRefetchQueries?: PureQueryOptions[];
}

const LineSettingsDialog: FC<Properties> = (props) => {
  const { classes } = useStyles();
  const [t, i18n] = useTranslation();
  const width = useWidth();

  const { open, handleOpenClose, key, refetchQueries, deleteRefetchQueries, ignoreDiscardValidation, lineId } = props;

  const [sensorGroup, setSensorGroup] = useState<SensorGroup | null>(null);
  const [nodes, setNodes] = useState<Types.GraphNode[]>([]);
  const [links, setLinks] = useState<Types.GraphLink[]>([]);
  const [bottleneckSensor, setBottleneckSensor] = useState<
    PartialSensor | Schema.LineSettingsQuery['line']['nodes'][0]['sensor'] | null
  >(null);
  const [lineInfo, setLineInfo] = useState<LineInfo>({
    name: '',
    description: '',
  });
  const [formRef, setFormRef] = useState<React.RefObject<ValidatorForm>>(React.createRef());
  const [hasChanges, setHasChanges] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);

  const waitingOnData = !lineInfo || (!lineInfo.name && !sensorGroup);
  const errorFetchingData = !waitingOnData && !sensorGroup && new Error('Sensor Group appeared to be undefined!');

  const { loading, error, data } = useQuery<Schema.LineSettingsQuery, Schema.LineSettingsQueryVariables>(lineSettings, {
    ssr: false,
    skip: !open,
    fetchPolicy: 'no-cache', // Temp fix until we work out our cache
    errorPolicy: 'all', // Necessary for returning partial data.
    partialRefetch: true,
    onCompleted: (data) => onCompleted(data),
    variables: { lineId },
  });

  const onCompleted = (data: Schema.LineSettingsQuery) => {
    if ((!isEmpty(data) && !lineInfo) || !lineInfo.name) {
      const bottleneckSensor =
        data?.line?.nodes.filter((node) => node.type === Schema.NodeType.Main)[0]?.sensor || null;
      setBottleneckSensor(bottleneckSensor);
      setNodes(reshapeFetchedNodes(data.line.nodes.filter((node) => node.type !== Schema.NodeType.Main)));
      setLinks(reshapeFetchedEdges(data.line.edges));
      setSensorGroup(getSensorGroup(data));
      setLineInfo({
        name: data.line.name,
        description: data.line.description || '',
      });
    }
  };

  const handleReset = () => {
    setFormRef(React.createRef());
    setNodes([]);
    setLinks([]);
    setSensorGroup(null);
    setBottleneckSensor(null);
    setLineInfo({
      name: '',
      description: '',
    });
    setHasChanges(false);
    setDiscardDialogOpen(false);
  };

  const handleCloseAndReset = (discard?: boolean) => () => {
    if (hasChanges && !ignoreDiscardValidation && !discard) {
      setDiscardDialogOpen(true);
    } else {
      handleReset();
      handleOpenClose(false);
    }
  };

  const handleDiscardResponse = (discard: boolean) => {
    if (discard) {
      handleReset();
      handleOpenClose(false);
    } else {
      setDiscardDialogOpen(false);
    }
  };

  const sensorIsPartOfALine = (
    sensor: NonNullable<NonNullable<Schema.LineSettingsQuery['line']['mainSensor']>['groups']>[0]['sensors'][0],
  ) => {
    return sensor?.peripheralInformation?.node?.peripheralId;
  };

  const reshapeNodes = (nodes: Types.GraphNode[], nodeLinks: Types.GraphLink[]) => {
    return nodes.map((node) => {
      // Mark selected scrap sensors as such
      const isScrapSensor = nodeLinks.find(
        (x) =>
          (x.source === node.peripheralId && x.target === bottleneckSensor?.peripheralId) ||
          (x.source === bottleneckSensor?.peripheralId && x.target === node.peripheralId),
      );
      let nodeType;
      if (node.nodeType === Schema.NodeType.Main) {
        nodeType = node.nodeType;
      } else if (isScrapSensor) {
        nodeType = Schema.NodeType.Scrap;
      } else {
        nodeType = sensorTypeToNodeType(node.sensorType);
      }
      return {
        type: nodeType,
        peripheralId: node.peripheralId,
      };
    });
  };

  const reshapeLinks = (links: Types.GraphLink[]) => {
    return links
      ? links.map((link) => {
          return {
            from: link.source,
            to: link.target,
          };
        })
      : [];
  };

  const reshapeFetchedNodes = (nodes: Schema.LineSettingsQuery['line']['nodes']) => {
    const reshaped: Types.GraphNode[] = nodes.filter(Types.isTruthy).map((node) => {
      return {
        sensorName: node.sensor?.name,
        sensorDescription: node.sensor?.description,
        peripheralId: node.sensor?.peripheralId,
        nodeType: node.type || Schema.NodeType.Counter,
        registerStops: node.type === Schema.NodeType.Main,
        oee: node.type === Schema.NodeType.Main,
        sensorType: node.sensor?.config.type,
      } as Types.GraphNode;
    });

    return reshaped;
  };

  const reshapeFetchedEdges = (edges: Schema.LineSettingsQuery['line']['edges']) => {
    const graphEdges = edges
      .filter((edge) => edge.from && edge.to)
      .map((edge) => {
        const newGraphLink: Types.GraphLink = {
          source: edge.from!,
          target: edge.to,
        };
        return newGraphLink;
      });
    return graphEdges;
  };

  const handleClose = () => {
    handleCloseAndReset(!open)();
  };

  const notImplemented = () => {};

  const getSensorGroup = (data: Schema.LineSettingsQuery) => {
    const group =
      data?.line?.mainSensor?.groups?.find((group) => group.lineIds.find((id) => id === data.line.id) !== undefined) ||
      null;
    const sensorGroup = {
      id: group?.id,
      name: group?.name,
      sensors: group?.sensors,
      availableSensors: group?.sensors.filter(
        (sensor) =>
          sensor &&
          (!sensorIsPartOfALine(sensor) || data.line.nodes.some((node) => sensor.peripheralId === node.peripheralId)),
      ),
      unavailableSensors: group?.sensors.filter((s) => s && sensorIsPartOfALine(s)),
    } as SensorGroup;
    return sensorGroup;
  };

  const sensorTypeToNodeType = (sensorType: Schema.SensorType | null | undefined): Schema.NodeType => {
    if (!sensorType) {
      return Schema.NodeType.Counter;
    }
    switch (sensorType) {
      case Schema.SensorType.COUNTER:
      case Schema.SensorType.COUNTER_ACCUMULATE:
      case Schema.SensorType.COUNTER_SPEED:
      case Schema.SensorType.DISCRETE:
      case Schema.SensorType.MANUAL_PROCESS:
      case Schema.SensorType.MEASUREMENT:
        return Schema.NodeType.Counter;
      case Schema.SensorType.EVENT:
        return Schema.NodeType.Event;
      default:
        console.warn('Unrecognized sensor type!');
        return Schema.NodeType.Counter;
    }
  };

  const onAddScrapSensorNode = (peripheralId: string, isBeforeMain: boolean) => {
    const updatedLinks = [...links];
    const source = isBeforeMain ? peripheralId : bottleneckSensor!.peripheralId;
    const target = isBeforeMain ? bottleneckSensor!.peripheralId : peripheralId;

    updatedLinks.push({ source, target });

    setLinks(updatedLinks);
  };

  const onRemoveScrapSensorNode = (peripheralId: string) => {
    let updatedLinks = links.filter((link) => link.source !== peripheralId && link.target !== peripheralId);
    setLinks(updatedLinks);
  };

  const onSelectBottleneckSensor = useCallback(
    (sensor: PartialSensor) => {
      if (sensor.peripheralId === bottleneckSensor?.peripheralId) {
        return;
      }

      setBottleneckSensor(sensor);
      setNodes(nodes.filter((node) => node.peripheralId !== sensor.peripheralId));
    },
    [nodes, setNodes, setBottleneckSensor],
  );

  const onSelectSensorWithLineSettings = (sensor: PartialSensor) => {
    const isCamera = sensor.peripheralType === Schema.PeripheralType.CAMERA;

    const existingSensorNode = nodes.find((node) => node.peripheralId === sensor.peripheralId);
    const id = existingSensorNode?.peripheralId;
    if (existingSensorNode && existingSensorNode.nodeType !== Schema.NodeType.Main) {
      const updatedNodes = nodes.filter(
        (node) => node.peripheralId !== id && node.peripheralId !== bottleneckSensor?.peripheralId,
      );
      const updatedLinks = links.filter((link) => link.source !== id && link.target !== id);
      setNodes(updatedNodes);
      setLinks(updatedLinks);
    } else if (!nodes.some((node) => node.peripheralId === sensor.peripheralId)) {
      const newGraphNode: Types.GraphNode = {
        sensorName: sensor.name,
        sensorDescription: sensor.description,
        peripheralId: sensor.peripheralId,
        sensorType: sensor.config.type,
        nodeType: isCamera ? Schema.NodeType.Camera : sensorTypeToNodeType(sensor.config.type),
        registerStops: false,
        oee: false,
      };
      setNodes([...nodes, newGraphNode]);
    }
  };

  const renderContent = () => {
    if (loading || (error && !data) || waitingOnData || errorFetchingData) {
      return <LoadingOrError loading={loading || waitingOnData} error={error || errorFetchingData} />;
    }

    return (
      <Grid container spacing={2} alignItems="stretch" justifyContent="flex-start">
        <Grid item xs={12} sm={12} md={12}>
          <SpecifyLineInfo lineInfo={lineInfo} onChangeLineInfo={setLineInfo} />
        </Grid>
        <Grid item xs={12}>
          <SelectSensor
            title={
              t(['line:selectBottleneckCounter'], {
                defaultValue: 'Select the bottleneck (main sensor) of the line',
              }) + '*'
            }
            disabledSensorId={undefined}
            sensors={(sensorGroup?.availableSensors || []).filter(Types.isTruthy)}
            selectedSensorIds={bottleneckSensor ? [bottleneckSensor.peripheralId] : []}
            onSelectSensor={onSelectBottleneckSensor}
            selectDefault
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <SelectSensor
            title={t(['line:clickSensorToAddOrRemove'], {
              defaultValue: 'Click on a sensor to add or remove it from the line',
            })}
            selectedSensorIds={nodes.map((node) => node.peripheralId)}
            sensors={sensorGroup?.availableSensors?.filter(Types.isTruthy) || []}
            onSelectSensor={onSelectSensorWithLineSettings}
            disabledSensorId={bottleneckSensor?.peripheralId}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <LineListTopology
            // Only non-bottleneck and counter sensors can be scrap
            availableNodes={nodes.filter(
              (node) =>
                node.nodeType !== Schema.NodeType.Main &&
                (node.sensorType === Schema.SensorType.COUNTER ||
                  node.sensorType === Schema.SensorType.COUNTER_ACCUMULATE),
            )}
            mainSensorNodeName={bottleneckSensor?.name}
            mainSensorNodePeripheralId={bottleneckSensor?.peripheralId}
            nodeLinks={links}
            onAddScrapSensor={onAddScrapSensorNode}
            onRemoveScrapSensor={onRemoveScrapSensorNode}
          />
        </Grid>
      </Grid>
    );
  };

  const allNodes: Types.GraphNode[] = [
    ...nodes,
    {
      sensorName: bottleneckSensor?.name!,
      sensorDescription: bottleneckSensor?.description!,
      peripheralId: bottleneckSensor?.peripheralId!,
      sensorType: bottleneckSensor?.config.type! as Schema.SensorType,
      nodeType: Schema.NodeType.Main,
      registerStops: false,
      oee: false,
    },
  ];

  return (
    <Dialog open={open} maxWidth={'md'} fullWidth onClose={handleClose} scroll="body">
      <ValidatorForm key={key} ref={formRef} method="post" instantValidate={true} onSubmit={notImplemented}>
        <DialogHeader
          title={t(['line:manageLineSettings'], { defaultValue: 'Manage line settings' })}
          onClose={handleClose}
        />
        <DialogContent>
          {renderContent()}
          <WrapRequiredFieldsDescriptionComponent />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {!waitingOnData ? (
            <>
              <DeleteButton
                lineId={lineId}
                handleOpenClose={handleOpenClose}
                open={open}
                refetchQueries={deleteRefetchQueries}
              />
              <div>
                <Button onClick={() => handleOpenClose(false)} color="primary">
                  <Close className={classes.closeButton} />
                  {isWidthUp('md', width) ? t(['shared:discardChanges'], { defaultValue: 'Discard changes' }) : ''}
                </Button>
                {bottleneckSensor && (
                  <UpdateButton
                    lineId={lineId}
                    lineInfo={lineInfo}
                    nodes={reshapeNodes(allNodes, links)}
                    edges={reshapeLinks(links)}
                    languageCode={i18n.language ? i18n.language : ''}
                    handleOpenClose={handleOpenClose}
                    open={open}
                    refetchQueries={refetchQueries}
                  />
                )}
              </div>
            </>
          ) : null}
        </DialogActions>
      </ValidatorForm>
      <DiscardChangesDialog open={discardDialogOpen} handleResponse={handleDiscardResponse} />
    </Dialog>
  );
};

export default LineSettingsDialog;
